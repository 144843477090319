import * as VueRouter from 'vue-router';
import { filter, switchMap, take } from 'rxjs/operators';
import { Auth } from '@/capacitors/services/AuthService';
import secureStorage from '@/utils/secure.storage.utils';

export const routes = [
  {
    path: '/',
    component: () => import('./views/index.vue'),
  },
  {
    path: '/authcallback',
    name: 'authcallback',
    component: () => import('./views/Auth.vue'),
  },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // return next();
  if (to.name !== 'authcallback') {
    Auth.Instance.initComplete$
      .pipe(
        filter((complete) => complete),
        switchMap(() => Auth.Instance.isAuthenticated$),
        take(1),
      )
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          // No merchant selected
          next();
          if (secureStorage.getItem('merchant_uid')) {
            // if (secureStorage.getItem(STAFF_KEY)) {
            //   next();
            //   // checkPermission(to, from, next);
            // } else {
            //   router.push('/login');
            // }
          } else {
            Auth.Instance.signIn();
          }
        } else {
          console.log('no auth');
          Auth.Instance.signIn();
        }
      });
  } else {
    next();
  }
});

export default router;
