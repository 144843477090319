<template>
  <svg aria-hidden="true" class="inline-block" :width="size" :height="size">
    <use :href="symbolId" :fill="color" />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
  name: 'SvgIcon',
});

const props = defineProps({
  prefix: {
    type: String,
    default: 'icon',
  },
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: '#333',
  },
  size: {
    type: [String, Number],
    default: '1em',
  },
});

const symbolId = computed(() => `#${props.prefix}-${props.name}`);
</script>
