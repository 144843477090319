import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './styles/style.css';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

import './styles/element-ui.scss';
import SvgIcon from '@/components/SvgIcon.vue';
import 'virtual:svg-icons-register';

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://bdac131683860386592d0bc74243876f@o102090.ingest.us.sentry.io/4507831892967424',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});

app.use(createPinia());
app.use(router);
app.use(ElementPlus);

app.component('SvgIcon', SvgIcon);

app.mount('#app');
