import CryptoJS from 'crypto-js';
import axios from 'axios';
import { isPlatform } from '@ionic/vue';

interface SecureStorageOptions {
  hash?: (key: string) => string;
}

export type IVault = {
  crm_account_id: string;
  crm_api_key: string;
};

export class SecureStorage {
  private static instance: SecureStorage;
  private storage: Storage;
  private key: string = '';
  private hash: (key: string) => string;
  private getSecretKey: () => string;

  public constructor(storage: Storage, options: SecureStorageOptions) {
    this.storage = storage;
    this.hash = options.hash || this.defaultHash;
    this.getSecretKey = this.genKey;
  }

  static getInstance(): SecureStorage {
    if (!SecureStorage.instance) {
      throw new Error('SecureStorage instance not created');
    }
    return SecureStorage.instance;
  }

  defaultHash(key: string): string {
    return CryptoJS.SHA256(key).toString();
  }

  getVault(): IVault | null {
    const vault = this.getItem('vault');
    if (vault) {
      return JSON.parse(vault);
    }
    return null;
  }

  async setVault(vault: IVault): Promise<void> {
    const merchantUid = this.getItem('merchant_uid');
    const encryptedValue = this.setItem('vault', JSON.stringify(vault));
    try {
      await axios.post(
        `${import.meta.env.VITE_BASE_URL}api/v5/merchants/${merchantUid}`,
        { vault: encryptedValue },
        {
          headers: {
            Authorization: 'Bearer ' + this.getAccessToken(),
          },
        },
      );
      // use for proxy server
      // await axios.post(
      //   `${import.meta.env.VITE_CRM_PROXY_URL}/wapi/add_api_key`,
      //   { api_key: vault.crm_api_key },
      //   {
      //     headers: {
      //       Authorization: 'Bearer ' + this.getAccessToken(),
      //     },
      //   },
      // );
    } catch (error) {
      console.error('Error setting vault:', error);
    }
  }

  getItem(key: string): string | null {
    key = this.hash(key);
    let value = this.storage.getItem(key);
    if (typeof value !== 'string') {
      return '';
    }
    const secretKey = this.genKey();
    if (!secretKey) throw new Error('Secret key not available');
    try {
      value = CryptoJS.AES.decrypt(value, secretKey).toString(CryptoJS.enc.Utf8);
      return value;
    } catch (e) {
      console.log('Error decrypting value', e);
      return null;
    }
  }

  setItem(key: string, value: string, encryption = true): string {
    key = this.hash(key);
    if (encryption) {
      const secretKey = this.genKey();
      if (!secretKey) throw new Error('Secret key not available');
      value = CryptoJS.AES.encrypt(value, secretKey).toString();
    }
    console.log('Setting vault', key, value);
    this.storage.setItem(key, value);
    console.log('returning vault', key, value);
    return value;
  }

  removeItem(key: string): void {
    key = this.hash(key);
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  setKey(id: number): string | null {
    return this.storage.key(id);
  }

  get length(): number {
    return this.storage.length;
  }

  genKey(): string {
    if (this.key) return this.key;
    const sub = this.getSubFromToken();
    if (sub) {
      this.key = this.deriveSecretKey(sub);
      return this.key;
    } else {
      throw new Error('No sub found in token');
    }
  }

  public getAccessToken(): string {
    const tokenKey = 'cap_sec_token_response';
    const encodedTokens = localStorage.getItem(tokenKey);
    if (encodedTokens) {
      const decodedTokens = atob(encodedTokens);
      const parsedTokens = JSON.parse(decodedTokens);
      if(isPlatform("ios") || isPlatform('android')) return parsedTokens.accessToken;
      else return parsedTokens.access_token;
    } else throw new Error('No access token found');
  }

  getSubFromToken(): string | null {
    const tokenKey = 'cap_sec_token_response';
    const encodedTokens = localStorage.getItem(tokenKey);
    if (encodedTokens) {
      const decodedTokens = atob(encodedTokens);
      const parsedTokens = JSON.parse(decodedTokens);
      const token = parsedTokens.access_token || parsedTokens.accessToken;
      const tokenParts = token.split('.');
      if (tokenParts.length === 3) {
        const payload = tokenParts[1];
        const decodedPayload = atob(payload);
        const parsedPayload = JSON.parse(decodedPayload);
        return parsedPayload.sub || null;
      }
    } else throw new Error('No token found');
    return null;
  }

  deriveSecretKey(sub: string): string {
    const salt = 'l/0J8JN1WpSwMe.K6z8z3w==';
    const iterations = 100000;
    const keyLength = 32;
    const derivedKey = CryptoJS.PBKDF2(sub, salt, {
      keySize: keyLength / 32,
      iterations: iterations,
      hasher: CryptoJS.algo.SHA256,
    });
    const secretKey = derivedKey.toString(CryptoJS.enc.Hex);
    return secretKey;
  }
}

const secureStorage = new SecureStorage(localStorage, {});

export default secureStorage;
