import { App } from '@capacitor/app';
import { AuthActions, AuthService } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';
import { isPlatform } from '@ionic/vue';
import { AxiosRequestor } from './AxiosService';
import { CapacitorRequestor } from './CapacitorService';
import { Browser } from 'ionic-appauth/lib/auth-browser';
import { ServicesBuilder } from '@/utils/setup.utils';

export class Auth {
  private static authService: AuthService | undefined;
  private static buildAuthInstance() {
    const browser = new CapacitorCompatibleBrowser();

    const requestor = isPlatform('ios') ? new CapacitorRequestor() : new AxiosRequestor();
    const authService = new AuthService(new CapacitorBrowser(), new CapacitorSecureStorage(), requestor);

    const host = window.location.origin;
    authService.authConfig = {
      client_id: import.meta.env.VITE_CLIENT_ID,
      server_host: import.meta.env.VITE_AUTH_SERVER_HOST,
      redirect_url:
        isPlatform('capacitor') || isPlatform('electron')
          ? import.meta.env.VITE_SCHEME + 'callback'
          : host + '/authcallback',
      end_session_redirect_url: isPlatform('capacitor')
        ? import.meta.env.VITE_SCHEME + 'endSession'
        : host + '/endSession',
      scopes: 'offline_access',
      pkce: true,
    };

    if (isPlatform('capacitor')) {
      App.addListener('appUrlOpen', async (data: any) => {
        console.log(data.url);
        if (data.url?.indexOf(authService.authConfig.redirect_url) === 0) {
          authService.authorizationCallback(data.url);
          //@ts-ignore
          Auth.Instance.events$.subscribe((action) => {
            if (action.action === AuthActions.SignInSuccess) {
              console.log('sigin success..');
              location.href = '/authcallback';
              // this.$router.push('/');
            }
          });
        } else if (data.url.indexOf(authService.authConfig.end_session_redirect_url) === 0) {
          await authService.endSessionCallback();
          // location.href = '/authcallback';
          setTimeout(() => {
            authService.signIn();
          }, 1000);
        }
      });
    }

    authService.init();

    authService.events$.subscribe(async (action) => {
      if (
        action.action === AuthActions.RefreshSuccess ||
        action.action === AuthActions.SignInSuccess ||
        action.action == AuthActions.LoadTokenFromStorageSuccess
      ) {
        if (action.tokenResponse) {
          if (isPlatform('ios') || isPlatform('android'))
            localStorage.setItem('cap_sec_token_response', btoa(JSON.stringify(action.tokenResponse)));
        }
        // await ServicesBuilder.initCoreServices();
        // await ServicesBuilder.initSignalR();
      }
    });

    return authService;
  }

  public static get Instance(): AuthService {
    if (!this.authService) {
      this.authService = this.buildAuthInstance();
    }

    return this.authService;
  }
}

class CapacitorCompatibleBrowser extends Browser {
  _browser: CapacitorBrowser = new CapacitorBrowser();
  constructor() {
    super();
  }
  closeWindow() {
    return this._browser.closeWindow();
  }
  showWindow(url: string) {
    if (isPlatform('electron')) {
      return new Promise<string>((resolve, reject) => {
        console.log(url);
        //@ts-ignore
        electron.openExternal(url);
        resolve('');
      });
    }
    return this._browser.showWindow(url);
  }
}
